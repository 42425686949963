define("ember-google-maps/components/-private-api/warn-missing-component", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/debug"], function (_exports, _component, _templateFactory, _component2, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{!-- Don’t {{yield}} anything. We don’t want to render blocks, like overlays. --}}
  */
  {
    "id": "Vw5P5gPC",
    "block": "[[],[],false,[]]",
    "moduleName": "ember-google-maps/components/-private-api/warn-missing-component.hbs",
    "isStrictMode": false
  });
  /**
   * Throw an assertion to warn the user when they call a component that isn't
   * included in the build. In development, we replace the excluded component with
   * this one instead.
   */
  class WarnMissingComponent extends _component2.default {
    constructor() {
      super(...arguments);
      let name = this.args.name;
      let message = `
Ember Google Maps couldn't find a map component called "${name}"!

If you are excluding certain map components from your app in your ember-cli-build.js, make sure to
include "${name}".

Learn more at: https://ember-google-maps.sandydoo.me/docs/advanced#treeshaking`;
      (true && (0, _debug.warn)(message, {
        id: 'ember-google-maps:warn-missing-component'
      }));
    }
  }
  _exports.default = WarnMissingComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, WarnMissingComponent);
});