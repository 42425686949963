define("evp-base/models/base-asset", ["exports", "@ember-data/model", "rsvp", "ember"], function (_exports, _model, _rsvp, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let BaseAssetModel = _exports.default = (_dec = (0, _model.attr)(), _dec2 = (0, _model.attr)(), _dec3 = (0, _model.hasMany)('page'), _class = class BaseAssetModel extends _model.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "aggregate", _descriptor, this);
      _initializerDefineProperty(this, "extra_attr", _descriptor2, this);
      _initializerDefineProperty(this, "page", _descriptor3, this);
    }
    get is_lease() {
      return !!this.lease_schedule;
    }
    get is_service() {
      return !!this.service_contract;
    }
    get is_costcenter() {
      return !!this.costcenter_id;
    }
    asset_attribute(attribute) {
      return this.get(attribute);
    }
    unknownProperty(attr) {
      if (attr !== "then" && typeof this.extra_attr !== "undefined" && typeof this.extra_attr[attr] !== "undefined") {
        return this.get("extra_attr")[attr];
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "aggregate", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "extra_attr", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "page", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
});