define("ember-google-maps/components/g-map/map-component", ["exports", "@ember/application", "@ember/component", "@glimmer/tracking", "@ember/debug", "ember-google-maps/component-managers/map-component-manager", "ember-google-maps/utils/options-and-events"], function (_exports, _application, _component, _tracking, _debug, _mapComponentManager, _optionsAndEvents) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.MapComponentAPI = MapComponentAPI;
  _exports.combine = combine;
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  function combine(base, extra) {
    return Object.defineProperties(base, Object.getOwnPropertyDescriptors(extra));
  }
  function MapComponentAPI(source) {
    let name = source.name ?? 'unknown';
    return {
      get map() {
        return source.map;
      },
      get [name]() {
        return source.mapComponent;
      },
      get mapComponent() {
        return source.mapComponent;
      }
    };
  }
  let MapComponent = _exports.default = (_class = class MapComponent {
    get publicAPI() {
      return MapComponentAPI(this);
    }
    get map() {
      var _this$context;
      return (_this$context = this.context) === null || _this$context === void 0 ? void 0 : _this$context.map;
    }
    constructor(owner, args, options, events) {
      _initializerDefineProperty(this, "mapComponent", _descriptor, this);
      _defineProperty(this, "boundEvents", []);
      (0, _application.setOwner)(this, owner);
      this.args = args;
      this.options = options;
      this.events = events;
      this.register();
    }
    setup() {}
    teardown(mapComponent) {
      var _this$onTeardown;
      this.boundEvents.forEach(({
        remove
      }) => remove());

      // Cleanup events by removing map.
      if (mapComponent) {
        var _mapComponent$setMap;
        (_mapComponent$setMap = mapComponent.setMap) === null || _mapComponent$setMap === void 0 ? void 0 : _mapComponent$setMap.call(mapComponent, null);
      }

      // Unregister from the parent component
      (_this$onTeardown = this.onTeardown) === null || _this$onTeardown === void 0 ? void 0 : _this$onTeardown.call(this);
    }
    register() {
      if (typeof this.args.getContext === 'function') {
        let {
          context,
          remove
        } = this.args.getContext(this.publicAPI, this.name);
        this.context = context;
        this.onTeardown = remove;
      }
    }

    /* Events */

    addEventsToMapComponent(mapComponent, events = {}, payload = {}) {
      (true && !(mapComponent) && (0, _debug.assert)('You need to pass in a map component', mapComponent));
      let boundEvents = (0, _optionsAndEvents.addEventListeners)(mapComponent, events, payload);
      this.boundEvents.concat(boundEvents);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "mapComponent", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
  (0, _component.setComponentManager)(owner => new _mapComponentManager.MapComponentManager(owner), MapComponent);
});