define("ember-leaflet-marker-cluster/components/marker-cluster-layer", ["exports", "ember-leaflet/components/base-layer", "ember-leaflet/components/marker-layer", "ember-leaflet/components/circle-layer"], function (_exports, _baseLayer, _markerLayer, _circleLayer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class MarkerClusterLayer extends _baseLayer.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "leafletOptions", [...this.leafletOptions, 'showCoverageOnHover', 'zoomToBoundsOnClick', 'spiderfyOnMaxZoom', 'removeOutsideVisibleBounds', 'animate', 'animateAddingMarkers', 'disableClusteringAtZoom', 'maxClusterRadius', 'polygonOptions', 'singleMarkerMode', 'spiderLegPolylineOptions', 'spiderfyDistanceMultiplier', 'iconCreateFunction']);
      _defineProperty(this, "leafletEvents", [...this.leafletEvents,
      // Marker clutster events
      'clusterclick', 'clusterdblclick', 'clustermousedown', 'clustermouseover', 'clustermouseout', 'clustercontextmenu', 'clusteradd', 'clusterremove', 'animationend', 'spiderfied', 'unspiderfied',
      // Marker events
      'click', 'dblclick', 'mousedown', 'mouseover', 'mouseout', 'contextmenu', 'dragstart', 'drag', 'dragend', 'move', 'remove', 'add', 'popupopen', 'popupclose']);
      _defineProperty(this, "componentsToYield", [...this.componentsToYield, {
        name: 'marker-layer',
        as: 'marker',
        component: _markerLayer.default
      }, {
        name: 'circle-marker-layer',
        as: 'circle-marker',
        component: _circleLayer.default
      }]);
    }
    createLayer() {
      return this.L.markerClusterGroup(...this.requiredOptions, this.options);
    }
  }
  _exports.default = MarkerClusterLayer;
});