define("ember-google-maps/components/g-map/directions", ["exports", "@ember/component", "@ember/template-factory", "ember-google-maps/components/g-map/map-component", "@ember/service", "@ember/object", "@glimmer/tracking", "rsvp", "ember-concurrency", "@sandydoo/tracked-maps-and-sets", "@ember/test-waiters", "ember-google-maps/effects/tracking"], function (_exports, _component, _templateFactory, _mapComponent, _service, _object, _tracking, _rsvp, _emberConcurrency, _trackedMapsAndSets, _testWaiters, _tracking2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.DirectionsAPI = DirectionsAPI;
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
  function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{yield (hash
    directions=this.directions
    route=(component "g-map/route" getContext=@getContext directions=this.directions)
    waypoint=(component "g-map/waypoint" getContext=this.getWaypoint)
  )}}
  */
  {
    "id": "9u0brP+g",
    "block": "[[[18,2,[[28,[37,1],null,[[\"directions\",\"route\",\"waypoint\"],[[30,0,[\"directions\"]],[50,\"g-map/route\",0,null,[[\"getContext\",\"directions\"],[[30,1],[30,0,[\"directions\"]]]]],[50,\"g-map/waypoint\",0,null,[[\"getContext\"],[[30,0,[\"getWaypoint\"]]]]]]]]]]],[\"@getContext\",\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "ember-google-maps/components/g-map/directions.hbs",
    "isStrictMode": false
  });
  function DirectionsAPI(source) {
    return {
      get directions() {
        return source.directions;
      },
      get waypoints() {
        return source.waypoints;
      }
    };
  }

  // TODO should this yield something like `isRunning`? That way you can handle loading states.
  let Directions = _exports.default = (_class = class Directions extends _mapComponent.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "googleMapsApi", _descriptor, this);
      _initializerDefineProperty(this, "directions", _descriptor2, this);
      _defineProperty(this, "waypointComponents", new _trackedMapsAndSets.TrackedSet());
    }
    get name() {
      return 'directions';
    }
    get publicAPI() {
      return DirectionsAPI(this);
    }
    get waypoints() {
      return [...(this.options.waypoints ?? []), ...this.serializedWaypoints];
    }
    get serializedWaypoints() {
      return Array.from(this.waypointComponents, ({
        location,
        stopover
      }) => {
        return {
          location,
          stopover
        };
      });
    }
    setup(options) {
      let newOptions = _objectSpread(_objectSpread({}, options), {}, {
        waypoints: this.waypoints
      });

      // ember-concurrency tracks its internal properties, so it ends up
      // triggering the effect a second time once it resolves. I guess we could
      // "changeset" the options to avoid this, but there's more. Because it runs
      // in the same computation as this effect, you can't even check `isRunning`
      // without triggering an error from Glimmer. That's not particularly great,
      // and I guess the solution might have to happen upstream (scheduling or
      // track/untrack frames). Let's see what comes out of Glimmer's effect
      // system and revisit.
      return (0, _tracking2.untrack)(() => this.fetchDirections.perform(newOptions));
    }
    *fetchDirections(options = {}) {
      var _this$events$onDirect, _this$events;
      let directionsService = yield this.googleMapsApi.directionsService;
      let request = new _rsvp.Promise((resolve, reject) => {
        directionsService.route(options, (response, status) => {
          if (status === 'OK') {
            resolve(response);
          } else {
            reject(status);
          }
        });
      });
      this.directions = yield request;
      (_this$events$onDirect = (_this$events = this.events).onDirectionsChanged) === null || _this$events$onDirect === void 0 ? void 0 : _this$events$onDirect.call(_this$events, this.publicAPI);
      return this.directions;
    }

    // Directions can just be restarted
    teardown() {}
    getWaypoint(waypoint) {
      this.waypointComponents.add(waypoint);
      return () => this.waypointComponents.delete(waypoint);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "googleMapsApi", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "directions", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "fetchDirections", [_emberConcurrency.keepLatestTask, _testWaiters.waitFor], Object.getOwnPropertyDescriptor(_class.prototype, "fetchDirections"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getWaypoint", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "getWaypoint"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, Directions);
});