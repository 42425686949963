define("ember-google-maps/component-managers/map-component-manager", ["exports", "@ember/component", "@ember/application", "@ember/service", "@ember/destroyable", "@ember/debug", "@ember/test-waiters", "ember-google-maps/utils/options-and-events", "ember-google-maps/effects/tracking"], function (_exports, _component, _application, _service, _destroyable, _debug, _testWaiters, _optionsAndEvents, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.MapComponentManager = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let testWaiter = (0, _testWaiters.buildWaiter)('ember-google-maps:map-component-waiter');
  let MapComponentManager = _exports.MapComponentManager = (_class = class MapComponentManager {
    get google() {
      return this.googleMapsApi.google;
    }
    get isFastBoot() {
      var _this$fastboot;
      return ((_this$fastboot = this.fastboot) === null || _this$fastboot === void 0 ? void 0 : _this$fastboot.isFastBoot) ?? false;
    }
    constructor(owner) {
      _initializerDefineProperty(this, "googleMapsApi", _descriptor, this);
      _defineProperty(this, "capabilities", (0, _component.capabilities)('3.13', {
        asyncLifecycleCallbacks: false,
        destructor: true,
        // The update hook updates every single component in the tree, which is slow
        // as molasses.
        updateHook: false,
        createArgs: true,
        prepareArgs: true
      }));
      this.owner = owner;
      (0, _application.setOwner)(this, owner);
      this.fastboot = owner.lookup('service:fastboot');
    }
    createComponent(Class, args) {
      let optionsTracker = new _optionsAndEvents.OptionsAndEvents(args.named);
      let {
        options,
        events
      } = optionsTracker;
      let component = new Class(this.owner, args.named, options, events);
      if (!this.isFastBoot) {
        // TODO: What happens when we fail to load the API?
        this.google.then(() => {
          this.setupMapComponent(component);
        });
      }
      return component;
    }
    destroyComponent(component) {
      if (component.mapComponent) {
        component === null || component === void 0 ? void 0 : component.teardown(component.mapComponent);
      }
      (0, _destroyable.destroy)(component);
    }
    getContext(component) {
      return component ?? {};
    }
    setupMapComponent(component) {
      (true && !(component.setup) && (0, _debug.assert)('Each map component needs to have a `setup` method.', component.setup));
      let token = testWaiter.beginAsync();
      let hasUpdate = typeof component.update === 'function';
      let effect, mapComponent, trackThisInstead;
      if (hasUpdate) {
        effect = (0, _tracking.setupEffect)(() => {
          if (mapComponent === undefined) {
            mapComponent = component.setup(component.options, component.events);
            if (mapComponent.length) {
              [mapComponent, trackThisInstead] = mapComponent;
            }
            component.mapComponent = mapComponent;
          } else {
            component.update(mapComponent, component.options);
          }
          testWaiter.endAsync(token);
          return trackThisInstead ?? mapComponent;
        });
      } else {
        effect = (0, _tracking.setupEffect)(() => {
          // Teardown the previous map component if it exists
          if (mapComponent) {
            component.teardown(mapComponent);
          }
          mapComponent = component.setup(component.options, component.events);
          component.mapComponent = mapComponent;
          testWaiter.endAsync(token);
          return mapComponent;
        });
      }

      // Destroy effects when the component is destroyed.
      (0, _destroyable.associateDestroyableChild)(component, effect);
      return mapComponent;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "googleMapsApi", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
});