define("ember-google-maps/utils/async-data", ["exports", "@glimmer/tracking", "ember-google-maps/utils/platform", "@ember/object/proxy", "@ember/object/promise-proxy-mixin"], function (_exports, _tracking, _platform, _proxy, _promiseProxyMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getAsync = getAsync;
  var _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let PromiseProxy = (_class = class PromiseProxy {
    constructor(promise) {
      _initializerDefineProperty(this, "isRejected", _descriptor, this);
      _initializerDefineProperty(this, "isFulfilled", _descriptor2, this);
      _initializerDefineProperty(this, "content", _descriptor3, this);
      this.promise = promise.then(result => {
        this.content = result;
        this.isFulfilled = true;
        return result;
      }).catch(error => {
        this.isRejected = true;
        throw error;
      });
      let get = (_target, prop) => {
        switch (prop) {
          case 'promise':
            return this.promise;
          case 'then':
          case 'catch':
          case 'finally':
            return this.promise[prop].bind(this.promise);
          default:
            if (this.isFulfilled && this.content) {
              return Reflect.get(this.content, prop);
            }
        }
      };
      return new Proxy(this, {
        get
      });
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "isRejected", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isFulfilled", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "content", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _class);
  function getAsync(prototype, key, desc) {
    let PROMISES = new WeakMap();
    function getter(...args) {
      let existingProxy = PROMISES.get(desc);
      if (existingProxy) {
        return existingProxy;
      }
      let promise = desc.get.call(this, ...args);
      let proxy;
      if (_platform.HAS_NATIVE_PROXY) {
        proxy = new PromiseProxy(promise);
      } else {
        proxy = getAsyncNoProxyFallback(promise);
      }
      PROMISES.set(desc, proxy);
      return proxy;
    }
    return {
      get: getter
    };
  }
  const ObjectPromiseProxy = _proxy.default.extend(_promiseProxyMixin.default);
  function getAsyncNoProxyFallback(promise) {
    return ObjectPromiseProxy.create({
      promise
    });
  }
});