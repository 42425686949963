define("ember-google-maps/components/g-map/typical-map-component", ["exports", "@ember/component", "@ember/template-factory", "ember-google-maps/components/g-map/map-component"], function (_exports, _component, _templateFactory, _mapComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{yield}}
  */
  {
    "id": "6W90LzK4",
    "block": "[[[18,1,null]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "ember-google-maps/components/g-map/typical-map-component.hbs",
    "isStrictMode": false
  });
  class TypicalMapComponent extends _mapComponent.default {
    get newOptions() {
      return this.options;
    }
    setup() {
      let mapComponent = this.newMapComponent(this.newOptions);
      this.addEventsToMapComponent(mapComponent, this.events, this.publicAPI);
      mapComponent.setMap(this.map);
      return mapComponent;
    }
    update(mapComponent) {
      var _mapComponent$setOpti;
      mapComponent === null || mapComponent === void 0 ? void 0 : (_mapComponent$setOpti = mapComponent.setOptions) === null || _mapComponent$setOpti === void 0 ? void 0 : _mapComponent$setOpti.call(mapComponent, this.newOptions);
      return mapComponent;
    }
  }
  _exports.default = TypicalMapComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, TypicalMapComponent);
});