define("evp-base/adapters/application", ["exports", "@ember-data/adapter/json-api", "@ember/string", "ember-get-config", "ember", "@ember/object"], function (_exports, _jsonApi, _string, _emberGetConfig, _ember, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //import RESTAdapter from '@ember-data/adapter/rest';
  var _default = _exports.default = _jsonApi.default.extend({
    namespace: _emberGetConfig.default.evcApiNamespace,
    host: _emberGetConfig.default.evcApiHost,
    session: _ember.default.inject.service(),
    store: _ember.default.inject.service(),
    pathForType(type) {
      return (0, _string.dasherize)(type);
    },
    ajax: function (url, type, hash) {
      if (_ember.default.isEmpty(hash)) hash = {};
      if (_ember.default.isEmpty(hash.data)) hash.data = {};
      let context = App.context;
      let filter_data = context.get('filter');
      let extend_dict = function extend(obj, src) {
        for (let key in src) {
          if (src.hasOwnProperty(key)) obj[key] = src[key];
        }
        return obj;
      };
      let filter_scope = !context.get('has_global_filter') ? location.hash : 'global';
      if (filter_data && filter_data[filter_scope]) {
        hash.data = extend_dict(hash.data, filter_data[filter_scope]);
      }
      if (type === 'PUT' || type === 'DELETE') {
        hash.data = hash.data || {};
        hash.data['_method'] = type;
        type = 'PUT';
      }

      /**
       * add language
       */
      let current_user = App.context.get('current_user');
      hash.data['lc'] = current_user ? current_user.get('current_language') : 1;
      return this._super(url, type, hash);
    },
    headers: (0, _object.computed)(function () {
      let token = this.get('session.data.authenticated.token');
      let current_user = App.context.get('current_user');
      if (typeof token === "undefined") {
        return {
          'Accept': 'application/vnd.api+json',
          'Content-Type': 'application/vnd.api+json'
        };
      } else {
        return {
          'Authorization': 'Bearer ' + token,
          'eview.platform.language': current_user ? current_user.get('current_language') : 1,
          'Accept': 'application/vnd.api+json',
          'Content-Type': 'application/vnd.api+json'
        };
      }
    })
  });
});