define("ember-google-maps/modifiers/g-map/did-insert", ["exports", "ember-modifier", "@ember/debug"], function (_exports, _emberModifier, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _emberModifier.modifier)(function gMapDidInsert(element, [callback, ...positional], named) {
    (true && !(typeof callback === 'function') && (0, _debug.assert)('`g-map/did-insert` expects a function as its first positional argument.', typeof callback === 'function'));
    callback(element, positional, named);
  });
});