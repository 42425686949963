define("ember-leaflet-marker-cluster/instance-initializers/register-component", ["exports", "ember-leaflet-marker-cluster/components/marker-cluster-layer"], function (_exports, _markerClusterLayer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize(appInstance) {
    let emberLeafletService = appInstance.lookup('service:ember-leaflet');
    if (emberLeafletService) {
      emberLeafletService.registerComponent('marker-cluster-layer', {
        as: 'marker-cluster',
        component: _markerClusterLayer.default
      });
    }
  }
  var _default = _exports.default = {
    initialize
  };
});