define("ember-google-maps/components/g-map", ["exports", "@ember/component", "@ember/template-factory", "ember-google-maps/components/g-map/map-component", "@glimmer/tracking", "@ember/object", "ember-google-maps/utils/helpers", "@ember/test-waiters", "@ember/debug"], function (_exports, _component, _templateFactory, _mapComponent, _tracking, _object, _helpers, _testWaiters, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#let (component 'g-map/canvas' onCanvasReady=(fn this.getCanvas)) as |Canvas|}}
    {{#unless (has-block-params)}}
      {{!-- Compatibility: @classNames should be deprecated --}}
      <Canvas class={{@classNames}} ...attributes />
    {{/unless}}
  
    {{yield (hash
      map=this.map
      canvas=Canvas
      marker=(component 'g-map/marker' getContext=(fn this.getComponent))
      circle=(component 'g-map/circle' getContext=(fn this.getComponent))
      rectangle=(component 'g-map/rectangle' getContext=(fn this.getComponent))
      polyline=(component 'g-map/polyline' getContext=(fn this.getComponent))
      polygon=(component 'g-map/polygon' getContext=(fn this.getComponent))
      directions=(component 'g-map/directions' getContext=(fn this.getComponent))
      control=(component 'g-map/control' getContext=(fn this.getComponent))
      overlay=(component 'g-map/overlay' getContext=(fn this.getComponent))
      infoWindow=(component 'g-map/info-window' getContext=(fn this.getComponent))
      autocomplete=(component 'g-map/autocomplete' getContext=(fn this.getComponent))
      customComponentTemplate=(component 'template' getContext=(fn this.getComponent))
      )}}
  {{/let}}
  */
  {
    "id": "OJJr4knJ",
    "block": "[[[44,[[50,\"g-map/canvas\",0,null,[[\"onCanvasReady\"],[[28,[37,2],[[30,0,[\"getCanvas\"]]],null]]]]],[[[41,[51,[49,[30,4]]],[[[1,\"    \"],[8,[30,1],[[16,0,[30,2]],[17,3]],null,null],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[18,4,[[28,[37,6],null,[[\"map\",\"canvas\",\"marker\",\"circle\",\"rectangle\",\"polyline\",\"polygon\",\"directions\",\"control\",\"overlay\",\"infoWindow\",\"autocomplete\",\"markerClusterer\"],[[30,0,[\"map\"]],[30,1],[50,\"g-map/marker\",0,null,[[\"getContext\"],[[28,[37,2],[[30,0,[\"getComponent\"]]],null]]]],[50,\"g-map/circle\",0,null,[[\"getContext\"],[[28,[37,2],[[30,0,[\"getComponent\"]]],null]]]],[50,\"g-map/rectangle\",0,null,[[\"getContext\"],[[28,[37,2],[[30,0,[\"getComponent\"]]],null]]]],[50,\"g-map/polyline\",0,null,[[\"getContext\"],[[28,[37,2],[[30,0,[\"getComponent\"]]],null]]]],[50,\"g-map/polygon\",0,null,[[\"getContext\"],[[28,[37,2],[[30,0,[\"getComponent\"]]],null]]]],[50,\"g-map/directions\",0,null,[[\"getContext\"],[[28,[37,2],[[30,0,[\"getComponent\"]]],null]]]],[50,\"g-map/control\",0,null,[[\"getContext\"],[[28,[37,2],[[30,0,[\"getComponent\"]]],null]]]],[50,\"g-map/overlay\",0,null,[[\"getContext\"],[[28,[37,2],[[30,0,[\"getComponent\"]]],null]]]],[50,\"g-map/info-window\",0,null,[[\"getContext\"],[[28,[37,2],[[30,0,[\"getComponent\"]]],null]]]],[50,\"g-map/autocomplete\",0,null,[[\"getContext\"],[[28,[37,2],[[30,0,[\"getComponent\"]]],null]]]],[50,\"g-map/marker-clusterer\",0,null,[[\"getContext\"],[[28,[37,2],[[30,0,[\"getComponent\"]]],null]]]]]]]]],[1,\"\\n\"]],[1]]]],[\"Canvas\",\"@classNames\",\"&attrs\",\"&default\"],false,[\"let\",\"component\",\"fn\",\"unless\",\"has-block-params\",\"yield\",\"hash\"]]",
    "moduleName": "ember-google-maps/components/g-map.hbs",
    "isStrictMode": false
  });
  function GMapPublicAPI(source) {
    return {
      get map() {
        return source.map;
      },
      get components() {
        return source.deprecatedPublicComponents;
      }
    };
  }
  let GMap = _exports.default = (_class = class GMap extends _mapComponent.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "canvas", _descriptor, this);
      _defineProperty(this, "components", new Set());
      // TODO Deprecate access to this and replace with API methods.
      _defineProperty(this, "deprecatedPublicComponents", {});
    }
    get publicAPI() {
      return GMapPublicAPI(this);
    }
    get map() {
      return this.mapComponent;
    }
    get newOptions() {
      var _this$options;
      (_this$options = this.options).zoom ?? (_this$options.zoom = 15);
      if (!this.args.center) {
        this.options.center = (0, _helpers.toLatLng)(this.args.lat, this.args.lng);
      }
      return this.options;
    }

    // TODO: What if canvas is conditional? Render helpers? Promise? Force a
    // visible canvas?
    setup(options, events) {
      let map = new google.maps.Map(this.canvas, this.newOptions);
      this.addEventsToMapComponent(map, events, this.publicAPI);
      if (typeof this.args.onLoad === 'function') {
        (true && !(false) && (0, _debug.deprecate)(`The \`onLoad\` event has been deprecated. You should replace it with \`onceOnIdle\`.

        If you had the following:

        <GMap @lat={{this.lat}} @lng={{this.lng}} @onLoad={{this.didLoadMap}} />

        Replace it with:

        <GMap @lat={{this.lat}} @lng={{this.lng}} @onceOnIdle={{this.didLoadMap}} />

        `.replace(/^[\s]+/gm, '\n'), false, {
          id: 'events.onLoad',
          until: '5.0.0',
          for: 'ember-google-maps',
          since: {
            enabled: '4.0.0-beta.8'
          }
        }));
        google.maps.event.addListenerOnce(map, 'idle', () => {
          this.args.onLoad(this.publicAPI);
        });
      }
      if (true /* DEBUG */) {
        this.pauseTestForIdle(map);
      }
      return map;
    }
    update(map) {
      map.setOptions(this.newOptions);
      if (true /* DEBUG */) {
        this.pauseTestForIdle(map);
      }
      return map;
    }

    // Pause tests until map is in an idle state.
    async pauseTestForIdle(map) {
      await new Promise(resolve => {
        google.maps.event.addListenerOnce(map, 'idle', () => resolve(map));
      });
    }
    getCanvas(canvas) {
      this.canvas = canvas;
    }
    getComponent(component, as = 'other') {
      let storedComponent = {
        component,
        as
      };
      this.components.add(storedComponent);
      this.addToDeprecatedPublicComponents(storedComponent);
      return {
        context: this.publicAPI,
        remove: () => {
          this.components.delete(storedComponent);
          this.removeFromDeprecatedPublicComponents(storedComponent);
        }
      };
    }
    addToDeprecatedPublicComponents({
      as,
      component
    }) {
      if (!(as in this.deprecatedPublicComponents)) {
        this.deprecatedPublicComponents[as] = [];
      }
      this.deprecatedPublicComponents[as].push(component);
    }
    removeFromDeprecatedPublicComponents({
      as,
      component
    }) {
      let group = this.deprecatedPublicComponents[as];
      let index = group.indexOf(component);
      if (index > -1) {
        group.splice(index, 1);
      }

      // For backwards compatibility, we don't remove the groups when they're
      // empty.
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "canvas", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "pauseTestForIdle", [_testWaiters.waitFor], Object.getOwnPropertyDescriptor(_class.prototype, "pauseTestForIdle"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getCanvas", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "getCanvas"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getComponent", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "getComponent"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, GMap);
});