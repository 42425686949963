define("evp-base/adapters/base-asset", ["exports", "evp-base/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class BaseAssetAdapter extends _application.default {
    createRecord(store, type, snapshot) {
      const url = `${this.host}/${this.namespace}/data/add`;
      let payload = snapshot.serialize({
        includeId: true
      });
      payload['meta'] = {
        "page": location.hash.split('/')[2]
      };
      return this.ajax(url, "POST", {
        'data': payload
      });
    }
    updateRecord(store, type, snapshot) {
      const url = `${this.host}/${this.namespace}/data/update`;
      let payload = snapshot.serialize({
        includeId: true
      });
      delete payload['data']['attributes']['aggregate'];
      payload['meta'] = {
        "page": location.hash.split('/')[2]
      };
      return this.ajax(url, "POST", {
        'data': payload
      });
    }
    pathForType(type) {
      return 'data/' + type;
    }
    urlForQuery(query) {
      return `${this.urlPrefix()}/endpoint/${query.pid}/asset`;
    }
    findHasMany(store, snapshot, url, relationship) {
      const id = snapshot.id,
        type = snapshot.modelName,
        self = this;
      url = this.urlPrefix(url, this.buildURL(type, id, null, 'findHasMany'));
      if (snapshot.record.get('query_params')) {
        const params = snapshot.record.get('query_params');
        const qp = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        url += "?" + qp;
      }

      //url += "?page[size]=25&page[number]=1";
      console.log(url);
      return self.ajax(url, 'GET');
    }
  }
  _exports.default = BaseAssetAdapter;
});